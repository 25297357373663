export default [
  {
    path: '/sign-up',
    component: () => import('@/views/auth/sign-up.vue'),
  },
  {
    path: '/sign-up/family',
    component: () => import('@/views/auth/sign-up-family.vue'),
  },
  {
    path: '/sign-up/school',
    component: () => import('@/views/auth/sign-up-school3.vue'),
  },
  {
    path: '/sign-in',
    redirect: '/sign-in/school'
  },
  {
    path: '/sign-in/family',
    component: () => import('@/views/auth/sign-in-family.vue'),
  },
  {
    path: '/sign-in/school',
    component: () => import('@/views/auth/sign-in-school.vue'),
  },
  {
    path: '/switch-family',
    redirect: '/switch'
  },
  {
    path: '/switch',
    component: () => import('@/views/auth/switch.vue'),
  },
  {
    path: '/sign-up/verified',
    component: () => import('@/views/auth/sign-up-verified.vue'),
  },
  {
    path: '/sign-out',
    component: () => import('@/views/auth/sign-out.vue'),
  },
  {
    path: '/reset-password/:signature/:timestamp/:userId',
    component: () => import('@/views/auth/reset-password.vue'),
  },
  {
    path: '/verify',
    component: () => import('@/views/auth/verify.vue'),
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/auth/forgot-password.vue')
  },
  {
    path: '/forgot-password-teacher',
    component: () => import('@/views/auth/forgot-password-teacher.vue')
  },
  {
    path: '/reset-password-teacher/:signature/:timestamp/:userId',
    component: () => import('@/views/auth/reset-password-teacher.vue'),
  },
]
