<script setup>
import { reactive, watch, computed, onMounted } from 'vue'
import Textfield2 from '@/components/v/textfield2.vue'
import Btn from '@/components/v/btn.vue'
import Loader from '@/components/v/loader.vue'
import app from '@/app'
import api from '@/api'
import auth from '@/app/auth'
import { track } from '@amplitude/analytics-browser'

const TIMER = 5 * 60
const TIMER_RESEND = 30
const error_registered = 'Email sudah terdaftar. Coba langsung masuk.'
const error_email = 'Format email tidak sesuai'
const error_password = 'Konfirmasi Kata Sandi tidak sesuai. Ulangi lagi. Pastikan Kata Sandi memiliki 6 karakter dan menggunakan angka atau simbol untuk sekuritas lebih baik.'

const enableGoogle = true

const state = reactive({
  step: 1,
  method: '', // social, email, phone
  loading: false,
  error: '',
  errorEmail: false,
  errorPassword: false,
  key: '',
  googleData: null,
  googleTokenData: null
})

const form = reactive({
  email: '',
  password: '',
  confirmPassword: '',
})

const props = defineProps({
  verify: Boolean
})
const emit = defineEmits(['successVerify', 'login'])

onMounted(() => {
  if (props.verify) {
    state.step = 2
    state.method = 'email'
    state.key = auth.profile.registerKey
    resendOtp()
  }
})

watch(form, () => {
  resetError()
})

function resetError() {
  state.error = ''
  state.errorEmail = false
  state.errorPassword = false
}

function register() {
  resetError()

  if (form.password !== form.confirmPassword) {
    state.errorPassword = true
    return
  }

  state.loading = true
  app.auth
    .register({
      email: form.email,
      password: form.password,
      confirmPassword: form.confirmPassword,
    })
    .then(res => {
      state.key = res.data?.registerKey || ''
      timerStart()

      state.method = 'email'
      state.step++
    })
    .catch(handleError)
    .finally(() => state.loading = false)
}

function registerSocial() {
  resetError()
  const body = {
    password: form.password,
    confirmPassword: form.password,
    googleToken: state.googleData.credential,
    googleUserID: state.googleTokenData.sub,
  }
  state.loading = true
  auth.registerGoogle(body)
    .then(() => {
      track('Sign Up')
      emit('success')
    })
    .catch(handleError)
    .finally(() => state.loading = false)
}

function handleError(res) {
  const errorCode = res?.errors?.[0].code
  const errorMessage = res?.errors?.[0].message
  if (errorCode === 208) {
    state.error = error_registered
  } else if (errorCode === 104) {
    if (errorMessage.includes('Email')) {
      state.error = error_email
    } else if (errorMessage.includes('Password')) {
      state.error = error_password
    }
  } else if (errorCode === 209) {
    state.error = error_password
  } else {
    state.error = errorMessage || 'Terjadi kesalahan.'
  }
}

const otp = reactive({
  code: '',
  error: false,
  errorResend: false,
  remainingSecond: 0,
  resendRemainingSecond: 0
})

const minuteSecond = computed(() => {
  const minutes = Math.floor(otp.remainingSecond / 60)
  const seconds = otp.remainingSecond - minutes * 60

  return String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
})

let intervalId = null
let resendIntervalId = null
function timerStart() {
  clearInterval(intervalId)
  otp.remainingSecond = TIMER
  intervalId = setInterval(() => {
    if (otp.remainingSecond > 0) {
      otp.remainingSecond--
    }
  }, 1000)

  clearInterval(resendIntervalId)
  otp.resendRemainingSecond = TIMER_RESEND
  resendIntervalId = setInterval(() => {
    if (otp.resendRemainingSecond > 0) {
      otp.resendRemainingSecond--
    }
  }, 1000)
}

function submitOtp() {
  otp.error = ''
  otp.errorResend = ''
  state.loading = true
  api.post(api.path.verifyOtp, {
    key: state.key,
    otpCode: otp.code
  })
    .then(res => {
      track('Sign Up')
      if (props.verify) {
        emit('successVerify', res.data?.token)
        return
      }
      auth.setToken(res.data?.token)
      auth.loadAuth()
      emit('success', res?.data?.token)
    })
    .catch(e => {
      otp.error = true
      console.error(e)
    })
    .finally(() => state.loading = false)
}

function resendOtp() {
  otp.error = ''
  otp.errorResend = ''
  state.loading = true
  api.post(api.path.resendOtp.replace(':key', state.key))
    .then(res => {
      timerStart()
    })
    .catch(e => {
      otp.errorResend = true
      console.error(e)
    })
    .finally(() => state.loading = false)
}

function onSuccessGoogle(e) {
  state.googleData = e
  state.step++
  state.method = 'social'
  fetchGoogleTokenInfo()
}

function fetchGoogleTokenInfo() {
  fetch('https://oauth2.googleapis.com/tokeninfo?id_token=' + state.googleData.credential)
    .then(res => res.json())
    .then(res => {
      state.googleTokenData = res
    })
    .catch(console.error)
}
</script>

<template>
  <div class="card font-jak">
    <div class="card__inner min-h-600px">
      <img src="@/assets/logo.svg" class="w-72px mx-auto mb-3">
      <div class="text-blue-3 text-xs text-center font-bold mb-4">
        AKUN KELUARGA
      </div>

      <!-- STEP 1 -->
      <div v-if="state.step === 1" class="text-center">
        <div class="text-2xl font-black mb-2">
          Daftar dulu, yuk!
        </div>
        <div class="text-blue-3 text-xs font-bold mb-5">
          STEP {{ state.step }} DARI 2
        </div>

        <!-- Google button -->
        <div v-if="enableGoogle">
          <div class="text-xs mb-2">
            Gunakan
          </div>
          <div class="mb-6 h-40px">
            <GoogleLogin :callback="onSuccessGoogle" :button-config="{ shape: 'pill', text: 'signup_with' }">
            </GoogleLogin>
          </div>
          <div class="text-xs mb-2">
            atau
          </div>
        </div>

        <!-- Email register -->
        <div>
          <form @submit.prevent="register">
            <div class="mb-2">
              <Textfield2 v-model="form.email" name="email" variant="bg-grey" placeholder="Masukkan Email">
              </Textfield2>
              <div v-if="state.error" class="text-secondary-line-blue text-xs text-center">
                {{ state.error }}
              </div>
            </div>

            <div class="mb-2">
              <Textfield2 v-model="form.password" name="password" type="password" class="mb-2" variant="bg-grey"
                placeholder="Buat Password">
              </Textfield2>
              <Textfield2 v-model="form.confirmPassword" name="confirmPassword" type="password" variant="bg-grey"
                placeholder="Ulangi Password">
              </Textfield2>
              <div v-if="state.errorPassword" class="text-secondary-line-blue text-xs text-center">
                Konfirmasi password salah. Ulangi.
              </div>
            </div>

            <div class="text-center text-10px my-5">
              <div class="text-grey-grey mb-2">Mendaftar berarti setuju dengan <a href="https://bacapibo.com/terms"
                  class="link !text-grey-black" target="_blank">Ketentuan Layanan</a></div>
              <div class="text-grey-grey">Mendaftar berarti setuju dengan <a href="https://bacapibo.com/privacy"
                  class="link !text-grey-black" target="_blank">Kebijakan Privasi</a></div>
            </div>

            <template v-if="state.loading">
              <Loader></Loader>
            </template>
            <template v-else>
              <Btn type="submit" class="mb-4">
                Daftar
              </Btn>
              <Btn class="bg-primary-blue" @click="emit('login')">
                Sudah punya akun? Login
              </Btn>
            </template>
          </form>
        </div>
      </div>

      <!-- STEP 2 EMAIL -->
      <div v-if="state.step === 2 && state.method === 'email'" class="text-center">
        <div class="text-2xl font-black mb-2">
          Verifikasi Email
        </div>
        <div class="text-blue-3 text-xs font-bold mb-5">
          STEP {{ state.step }} DARI 2
        </div>

        <div class="text-sm">
          Cek pesan di inbox emailmu untuk melihat kode verifikasi. Kode akan kadaluarwarsa dalam:
        </div>

        <div class="timer text-xl font-semibold text-center my-4">
          {{ minuteSecond }}
        </div>

        <form @submit.prevent="submitOtp">
          <div class="max-w-200px mx-auto mb-4">
            <Textfield2 v-model="otp.code" name="otp" :disabled="otp.remainingSecond <= 0" variant="bg-grey"
              placeholder="Masukkan 6 digit kode">
            </Textfield2>
            <div v-if="otp.error" class="text-primary-base text-xs">
              Kode salah. Coba ulangi lagi.
            </div>
          </div>

          <Loader v-if="state.loading"></Loader>
          <Btn v-else type="submit" class="mb-4" :disabled="otp.remainingSecond <= 0">
            Verifikasi Email
          </Btn>
        </form>

        <div v-if="!state.loading && otp.resendRemainingSecond <= 0" class="link text-xs my-3" @click="resendOtp">
          Kirim Ulang Kode Verifikasi
        </div>
        <div v-if="otp.errorResend" class="text-primary-base text-xs">
          Mohon tunggu 30 detik untuk kirim ulang kode verifikasi
        </div>
      </div>

      <!-- STEP 2 SOCIAL -->
      <div v-if="state.step === 2 && state.method === 'social'" class="text-center">
        <div class="text-2xl font-black mb-2">
          Konfimasi Password
        </div>
        <div class="text-blue-3 text-xs font-bold mb-5">
          STEP {{ state.step }} DARI 2
        </div>

        <div class="text-sm mb-10">
          Konfirmasi password sekali lagi agar kamu dapat langsung login.
        </div>

        <form @submit.prevent="registerSocial">
          <div class="mb-8">
            <Textfield2 v-model="form.password" name="password" type="password" variant="bg-grey"
              placeholder="Buat Password"></Textfield2>
            <div v-if="state.error" class="text-primary-base text-xs text-center">
              {{ state.error }}
            </div>
          </div>
          <Loader v-if="state.loading"></Loader>
          <Btn type="submit">
            Daftar & Login
          </Btn>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  @apply p-4;
  &__inner {
    @apply p-7 rounded-lg bg-white-base;
    box-shadow: 0px 4px 5px 5px rgba(0, 0, 0, 0.25);
  }
}
</style>
