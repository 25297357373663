export default [
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/dashboard.vue'),
  },
  {
    path: '/dashboard/parents',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard/child',
    redirect: '/dashboard'
  },
  {
    path: '/account',
    component: () => import('@/views/dashboard/account.vue'),
  },
  {
    path: '/membership',
    component: () => import('@/views/dashboard/membership2.vue'),
  },
  {
    path: '/transaction/:receiptID',
    component: () => import('@/views/dashboard/transaction.vue'),
  },
  {
    path: '/payment-success',
    component: () => import('@/views/dashboard/payment-success.vue'),
  },
]
