<script setup>
const props = defineProps({
  dark: Boolean,
  small: Boolean
})
</script>

<template>
  <div :class="['spinner-wrapper', { 'spinner-wrapper--dark': props.dark }]">
    <svg :class="['spinner', { 'spinner--small': props.small }]" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="6" stroke="currentColor"></circle>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.spinner-wrapper {
  @apply text-blue-base;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2px;
  &--dark {
    @apply text-white-base;
  }
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  &--small {
    width: 24px;
    height: 24px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
