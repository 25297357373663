export default [
  {
    path: '/game/select',
    component: () => import('@/views/game/game-select.vue'),
  },
  {
    path: '/game/parade',
    component: () => import('@/views/game/parade/parade.vue'),
  },
  {
    path: '/game/parade-scores/:id',
    component: () => import('@/views/game/parade/parade-scores.vue'),
  },
  {
    path: '/game/challenge',
    component: () => import('@/views/game/tantangan/tantangan.vue'),
  },

  // old paths
  {
    path: '/game',
    component: () => import('@/views/game/game.vue'),
  },
  {
    path: '/game/mission',
    component: () => import('@/views/game/game.vue'),
  },
  {
    path: '/game/parade/:id',
    component: () => import('@/views/game/parade/parade-detail.vue'),
  },
]
