<script setup>
import CardLogin from '@/views/auth/components/card-login.vue'
import CardRegister from '@/views/auth/components/card-register.vue'
import ClickOutside from '@/components/v/clickoutside.vue'
import auth from '@/app/auth'
import { useRouter } from 'vue-router';

const router = useRouter()

function onSuccessLogin() {
  const to = auth.isPremium
    ? '/switch'
    : '/membership'

  const url = auth.authRedirect || '/explore'
  if (url.startsWith('http')) {
    window.open(url, '_blank')
  } else {
    router.push(auth.authRedirect || to)
  }
  auth.authModal = null
  auth.authRedirect = null
}

function onSuccessRegister() {
  const url = auth.authRedirect || '/explore'
  if (url.startsWith('http')) {
    window.open(url, '_blank')
  } else {
    router.push(auth.authRedirect || '/explore')
  }
  auth.authModal = null
  auth.authRedirect = null
}

function closeModal() {
  auth.cart = null
  auth.authModal = null
  auth.authRedirect = null
}
</script>

<template>
  <div class="auth-overlay">
    <div class="max-w-400px mx-auto pt-16 md:pt-100px">
      <ClickOutside visible @update:visible="closeModal">
        <CardLogin v-if="auth.authModal === 'login'" @success="onSuccessLogin" @register="auth.authModal = 'register'"
          @school="auth.authModal = null; router.push('/sign-in/school')">
        </CardLogin>
        <CardRegister v-if="auth.authModal === 'register'" @success="onSuccessRegister" @login="auth.authModal = 'login'">
        </CardRegister>
      </ClickOutside>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.auth-overlay {
  @apply fixed top-0 left-0 w-screen h-screen overflow-y-auto z-20;
  background: rgba(#000000, 0.5);
  backdrop-filter: blur(4px);
}
</style>