<script setup>
// Vue script setup. See https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md

import defaultLayout from '@/layout/default.vue'
import { onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import app from '@/app'
import auth from '@/app/auth'
import Meta from '@/components/meta.vue'

import AuthOverlay from '@/views/auth/components/auth-overlay.vue'

const route = useRoute()
app.route = route

onBeforeMount(() => {
  auth.loadAuth()
})
</script>

<template>
  <Meta />
  <component :is="$route.meta.layout || defaultLayout">
    <router-view />
  </component>
  <AuthOverlay v-if="auth.authModal" />
</template>

<style>
:root {
  color-scheme: light only;
}

body {
  @apply font-main text-grey-black;
  font-weight: 400;
}

img {
  max-width: unset;
  width: 100%;
}
</style>

<style lang="scss">
.article {
  >*+* {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  ul {
    list-style-type: disc;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    font-size: large;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.link {
  @apply underline cursor-pointer font-semibold;
}
</style>