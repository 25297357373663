<script setup>
import { directive as vClickOutside } from 'click-outside-vue3'

defineProps({
  visible: Boolean,
})

const emit = defineEmits(['update:visible'])

function close() {
  emit('update:visible', false)
}
</script>

<template>
  <div v-if="visible" class="wrapper" v-click-outside="close">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  @apply overflow-hidden;
}
</style>
