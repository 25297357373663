import readingLayout from '@/layout/reading.vue'

export default [
  {
    path: '/explore',
    component: () => import('@/views/explore/explore.vue'),
  },
  {
    path: '/book/read/:bookId',
    component: () => import('@/views/book/read.vue'),
    meta: {
      layout: readingLayout,
    },
  },
  {
    path: '/collection/:slug',
    component: () => import('@/views/collection/detail.vue'),
  },
]
