export default [
  {
    path: '/literaton',
    component: () => import('@/views/event/literaton.vue'),
  },
  {
    path: '/literaton2-2022',
    component: () => import('@/views/event/literaton2.vue'),
  },
  {
    path: '/kompetisibukuanak',
    component: () => import('@/views/event/kompetisi.vue'),
  },
]
