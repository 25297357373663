<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'button',
  },
  color: {
    type: String,
    default: 'primary',
  },
  large: Boolean,
  small: Boolean,
  inline: Boolean,
  comic: Boolean,
  outline: Boolean,
  ghost: Boolean,
  outlineGhost: Boolean,
  disabled: Boolean,
  round: Boolean,
})

const colorMap = {
  primary: 'bg-primary-base',
  secondary: 'bg-secondary-base',
  tertiary: 'bg-tertiary-base',
  blue: 'bg-blue-base',
  purple: 'bg-purple-2'
}

const color = computed(() => {
  return colorMap[props.color]
})
</script>

<template>
  <button :class="[
    `btn ripple px-4 py-10px text-center text-white-base rounded-full`,
    `${inline ? 'inline' : 'block w-full'}`,
    `${color}`,
    `${outline
      ? 'border border-secondary-line-blue !bg-white-base !text-secondary-line-blue'
      : ''
    }`,
    `${ghost ? 'btn-ghost' : ''}`,
    `${outlineGhost ? 'btn-outline-ghost' : ''}`,
    { 'font-comic': comic },
    `hover:bg-blue-base`,
    `focus:outline-none`,
    `${disabled ? 'pointer-events-none !border-gray-5 !bg-gray-5' : ''}`,
    `${small ? '!text-xs !px-3 !py-1' : ''}`,
    `${large ? '!text-2xl' : ''}`,
    `${round ? '!rounded-full' : ''}`,
  ]" :type="type">
    <span>
      <slot />
    </span>
  </button>
</template>

<style lang="scss" scoped>
.btn {
  transition: all 0.3s;
}

.btn-ghost {
  background: none;
  border: 1px solid transparent;
}

.btn-outline-ghost {
  background: none;
  border: 1px solid white;
}
</style>
