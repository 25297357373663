<script setup>
import { computed, reactive } from 'vue'

const props = defineProps({
  modelValue: [String, Number],
  type: String,
  name: String,
  placeholder: String,
  autocomplete: String,
  autofocus: Boolean,
  disabled: Boolean,
  variant: { type: String, default: '' }, // bg-grey
  error: { type: Boolean, default: false },
  success: { type: Boolean, default: false },
  transparent: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'focus'])

const innerValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const state = reactive({
  visiblePassword: false
})

const inputType = computed(() => {
  if (props.type !== 'password') {
    return props.type
  }
  return state.visiblePassword
    ? 'text'
    : 'password'
})
</script>

<template>
  <div :class="[
    'textfield',
    props.variant ? 'variant-' + props.variant : '',
    props.error ? 'textfield--error' : props.success ? 'textfield--success' : '',
    props.transparent ? 'textfield--transparent' : '',
  ]">
    <slot name="prepend"></slot>
    <input v-model="innerValue" :type="inputType" :name="props.name" :placeholder="props.placeholder"
      :autocomplete="props.autocomplete" :disabled="props.disabled" @focus="emit('focus')"
      :autofocus="props.autofocus" />
    <template v-if="props.type === 'password'">
      <img v-if="state.visiblePassword" src="@/assets/icon/eye-open.svg" @click="state.visiblePassword = false"
        class="w-auto cursor-pointer">
      <img v-else src="@/assets/icon/eye-closed.svg" @click="state.visiblePassword = true"
        class="w-auto cursor-pointer">
    </template>
    <div v-if="props.error || props.success">
      <img v-if="props.error" src="@/assets/icon/input-error.svg" class="w-4">
      <img v-else src="@/assets/icon/input-success.svg" class="w-4">
    </div>
    <slot name="append"></slot>
  </div>
</template>

<style lang="scss" scoped>
.textfield {
  @apply flex items-center overflow-hidden gap-4;
  @apply w-full h-[40px] px-3 text-sm text-grey-black;
  @apply rounded-md border border-blue-50;
  @apply bg-white-base bg-none;
  input {
    @apply h-full w-full;
    outline: none;
    background: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      @apply text-navy-50;
    }
  }
  &.variant-bg-grey {
    @apply bg-grey-light;
  }

  &--error {
    @apply border-red-base;
  }

  &--success {
    @apply border-secondary-green;
  }

  &--transparent {
    background-color: transparent;
  }
}
</style>
