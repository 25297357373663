import dayjs from 'dayjs'
import 'dayjs/locale/id'
dayjs.locale('id')

export function getAssetUrl(path) {
  return new URL(`../assets/${path}`, import.meta.url).href
}

export function getAvatarUrl(name) {
  return new URL(`../assets/avatar/${name}.png`, import.meta.url).href
}

export function formatDate(date, formatString = 'D/M/YYYY') {
  return dayjs(date).format(formatString)
}

export function formatCurrency(number) {
  return number.toLocaleString('id-ID')
}

export function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date())
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  }
}

export function dayToMonth(day) {
  return day / 30
}

export default {
  getAssetUrl,
  getAvatarUrl,
  formatDate,
  formatCurrency,
  getTimeRemaining,
  dayToMonth
}
