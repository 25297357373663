export default [
  // {
  //   path: '/old',
  //   component: () => import('@/views/index.vue'),
  // },
  {
    path: '/',
    component: () => import('@/views/landing/landing-school.vue'),
  },
  {
    path: '/family',
    component: () => import('@/views/landing/landing.vue'),
  },
  {
    path: '/school',
    redirect: '/',
  },
  {
    path: '/activity-sheet',
    component: () => import('@/views/public/activity-sheet2.vue'),
  },
  // {
  //   path: '/help',
  //   component: () => import('@/views/public/ask-help.vue'),
  // },
  {
    path: '/ask-collab',
    component: () => import('@/views/public/collab.vue'),
    // component: () => import('@/views/public/ask-collab.vue'),
  },
  {
    path: '/pricing',
    component: () => import('@/views/public/pricing.vue'),
  },
  {
    path: '/pricing/:slug',
    component: () => import('@/views/public/pricing/pricing-ab.vue'),
  },
  {
    path: '/privacy',
    component: () => import('@/views/public/privacy.vue'),
  },
  {
    path: '/terms',
    component: () => import('@/views/public/terms.vue'),
  },
  {
    path: '/faq',
    component: () => import('@/views/public/faq.vue'),
  },
  {
    path: '/faq/:faqCatId',
    component: () => import('@/views/public/faq-detail.vue'),
  },
  {
    path: '/campaign/:slug',
    component: () => import('@/views/public/campaign.vue')
  },
  {
    path: '/loyalty-finish',
    component: () => import('@/views/public/loyalty-finish.vue')
  }
]
