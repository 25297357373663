import api from '@/api'
import auth from '@/app/auth'
import config from '@/app/config'
import utils from '@/app/utils'

const app = {
  api,
  auth,
  config,
  utils,
  route: null
}

api.init(app)

export default app
