<script setup>
import { reactive, watch, onMounted, computed } from 'vue'
import Textfield2 from '@/components/v/textfield2.vue'
import Btn from '@/components/v/btn.vue'
import Loader from '@/components/v/loader.vue'
import app from '@/app'

const emit = defineEmits(['forgot', 'success', 'register', 'school'])

const enableGoogle = true
const error_email_not_found = 'Email tidak terdaftar. Coba lagi.'

const state = reactive({
  loading: false,
  error: '',
  errorCode: 0,
  errorPassword: false,
  key: '',
  googleData: null,
  googleTokenData: null,
})

const form = reactive({
  email: '',
  password: '',
})

watch(form, () => {
  resetError()
})

function resetError() {
  state.error = ''
  state.errorCode = 0
  state.errorPassword = ''
}

function login() {
  resetError()
  state.loading = true
  app.auth
    .login({
      loginType: 'family',
      password: form.password,
      usernameEmail: form.email.trim(),
    })
    .then(() => {
      emit('success')
    })
    .catch(handleError)
    .finally(() => state.loading = false)
}

function loginGoogle() {
  state.loading = true
  app.auth
    .loginGoogle({
      googleToken: state.googleData.credential,
      googleUserID: state.googleTokenData.sub
    })
    .then(() => {
      emit('success')
    })
    .catch(handleError)
    .finally(() => state.loading = false)
}

function handleError(res) {
  const errorCode = res?.errors?.[0].code
  const errorMessage = res?.errors?.[0].message
  if (errorCode === 201) {
    state.error = error_email_not_found
  } else if (errorCode === 203) {
    state.errorPassword = true
  } else {
    state.error = errorMessage || 'Terjadi kesalahan.'
  }
  state.errorCode = errorCode
}

async function onSuccessGoogle(e) {
  state.googleData = e
  state.step++
  state.method = 'social'
  await fetchGoogleTokenInfo()
  loginGoogle()
}

async function fetchGoogleTokenInfo() {
  return fetch('https://oauth2.googleapis.com/tokeninfo?id_token=' + state.googleData.credential)
    .then(res => res.json())
    .then(res => {
      state.googleTokenData = res
    })
    .catch(console.error)
}
</script>

<template>
  <div class="card font-jak">
    <div class="card__inner min-h-600px">
      <img src="@/assets/logo.svg" class="w-72px mx-auto mb-3">
      <div class="text-blue-3 text-xs text-center font-bold mb-4">
        AKUN KELUARGA
      </div>

      <!-- STEP 1 -->
      <div class="text-center">
        <div class="text-2xl font-black mb-10">
          Login
        </div>

        <!-- Google button -->
        <div v-if="enableGoogle">
          <div class="text-xs mb-2">
            Gunakan
          </div>
          <div class="mb-6 h-40px">
            <GoogleLogin :callback="onSuccessGoogle" :button-config="{ shape: 'pill' }"></GoogleLogin>
          </div>
          <div class="text-xs mb-2">
            atau
          </div>
        </div>

        <!-- Email register -->
        <div>
          <form @submit.prevent="login">
            <div class="mb-2">
              <Textfield2 v-model="form.email" name="email" variant="bg-grey" placeholder="Masukkan Email">
              </Textfield2>
              <div v-if="state.error" class="text-secondary-line-blue text-xs text-center">
                {{ state.error }}
              </div>
            </div>

            <div class="mb-2">
              <Textfield2 v-model="form.password" name="password" type="password" class="mb-2" variant="bg-grey"
                placeholder="Masukkan Password">
              </Textfield2>
              <div v-if="state.errorPassword" class="text-secondary-line-blue text-xs text-center">
                Password salah. Coba lagi.
              </div>
            </div>

            <template v-if="state.loading">
              <Loader></Loader>
            </template>
            <template v-else>
              <Btn type="submit" class="bg-primary-blue mb-4">
                Login
              </Btn>
              <RouterLink to="/forgot-password">
                <div class="link text-xs">
                  Lupa Password?
                </div>
              </RouterLink>
            </template>

            <div class="mt-80px text-sm">
              Belum Punya Akun? <a @click="emit('register')" class="link">Yuk, daftar!</a>
            </div>
            <div class="mt-4 text-sm">
              Untuk Akun Sekolah, <a @click="emit('school')" class="link">login di sini</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  @apply p-4;
  &__inner {
    @apply p-7 rounded-lg bg-white-base;
    box-shadow: 0px 4px 5px 5px rgba(0, 0, 0, 0.25);
  }
}
</style>
