import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/app/auth'

import authRoutes from '@/router/auth'
import dashboardRoutes from '@/router/dashboard'
import eventRoutes from '@/router/event'
import exploreRoutes from '@/router/explore'
import gameRoutes from '@/router/game'
import inboxRoutes from '@/router/inbox'
import libraryRoutes from '@/router/library'
import previewRoutes from '@/router/preview'
import publicRoutes from '@/router/public'
import searchRoutes from '@/router/search'

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...eventRoutes,
  ...exploreRoutes,
  ...gameRoutes,
  ...inboxRoutes,
  ...libraryRoutes,
  ...previewRoutes,
  ...publicRoutes,
  ...searchRoutes,
  { path: '/404', component: () => import('@/views/public/404.vue') },
  { path: '/502', component: () => import('@/views/public/502.vue') },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/public/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
  if (to.path !== '/verify' && to.path !== '/sign-out' && to.path !== '/sign-up/school') {
    if (auth.isFamily && !auth.profile?.isEmailVerified) {
      return { path: '/verify' }
    }
    if ((auth.isAdmin || auth.isTeacher) && !auth.profile?.isEmailVerified) {
      return { path: '/sign-up/school?key=' + res.data.registerKey }
    }
  }
})

export default router
