<script setup>
import { ref, watch } from '@vue/runtime-core'
import { useIntersectionObserver } from '@/lib/intersection-observer'
const el = ref(null)

const { isIntersecting } = useIntersectionObserver(el)
const emit = defineEmits(['intersect'])

watch(isIntersecting, () => {
  emit('intersect', isIntersecting)
})
</script>

<template>
  <div class="observer" ref="el"></div>
</template>