<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'button',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  inline: Boolean,
  shadow: Boolean,
  disabled: Boolean,
  small: Boolean,
})

const variant = {
  'high': 'b-high grad-orange-red',
  'primary': 'b-primary bg-primary-red',
  'secondary': 'b-secondary bg-primary-blue',
  'light': 'b-light',
  'outline': 'b-outline',
  'outline-white': 'b-outline-white',
}
</script>

<template>
  <button :class="[
    `btn`,
    variant[props.variant],
    `${props.shadow ? 'b-shadow' : ''}`,
    `${props.inline ? 'inline-block' : 'block w-full'}`,
    `${props.disabled ? 'b-disabled' : ''}`,
    `${props.small ? 'b-small' : ''}`
  ]" :type="props.type">
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.btn {
  @apply px-4 py-2 text-center text-white-base rounded-full relative overflow-hidden;
  @apply font-bold;
  transition: background 0.3s, border 0.3s;
  background-origin: border-box;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #959FD0 !important;
  }
}

.b-light {
  @apply bg-blue-20 text-blue-100;
}

.b-outline {
  @apply text-primary-blue border border-primary-blue;
}

.b-outline-white {
  @apply text-white-base border border-white-base;
}

.b-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.b-disabled {
  @apply bg-navy-20 text-navy-35;
}

.b-small {
  @apply py-0 text-sm;
}
</style>
