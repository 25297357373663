<script setup>
import { useMeta } from '@/lib/meta'
import config from '@/app/config'

const meta = useMeta()

const imageUrl = new URL('../assets/logo.svg', import.meta.url).href
</script>

<template>
  <Teleport to="head">
    <meta name="description" :content="meta.desc.value" />
    <meta property="og:title" :content="meta.title.value" />
    <meta property="og:description" :content="meta.desc.value" />
    <meta property="og:site_name" :content="config.title" />
    <meta property="og:image" :content="imageUrl" />
    <meta name="twitter:title" :content="meta.title.value" />
    <meta name="twitter:description" :content="meta.desc.value" />
    <meta name="twitter:site" :content="config.title" />
    <meta name="twitter:image" :content="imageUrl" />
  </Teleport>
</template>
