
import { reactive, computed, watch, onBeforeUnmount } from '@vue/runtime-core'
import config from '@/app/config'

const state = reactive({
  title: '',
  desc: ''
})


const computedTitle = computed(() => {
  return state.title ? state.title : config.longTitle
})

const computedDesc = computed(() => {
  return state.desc || config.desc
})

watch(computedTitle, setTitle)

let titleEl = document.getElementsByTagName('title')[0]
function setTitle() {
  titleEl.innerHTML = computedTitle.value
}

function setMeta(params) {
  Object.assign(state, params)
}

setTitle()

export function useMeta({ title, desc } = {}) {
  setMeta({ title, desc })

  onBeforeUnmount(() => {
    Object.assign(state, { title: '', desc: '' })
  })

  return {
    state,
    title: computedTitle.value,
    desc: computedDesc,
    setMeta
  }
}