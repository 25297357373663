<script setup>
import { ref } from 'vue';
import Observer from './observer.vue';

const props = defineProps({
  height: { type: String, default: '0px' }
})

const loading = ref(false)
const loaded = ref(false)
</script>

<template>
  <div :style="loaded ? '' : 'min-height: ' + height">
    <Observer v-if="!loaded" @intersect="loading = true"></Observer>
    <Suspense v-if="loading" @resolve="loaded = true">
      <slot></slot>
    </Suspense>
  </div>
</template>