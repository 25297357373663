import readingLayout from '@/layout/reading.vue'

export default [
  {
    path: '/preview/book/:bookId/:signature',
    component: () => import('@/views/book/read.vue'),
    meta: {
      layout: readingLayout,
    },
  },
  {
    path: '/preview/collection/:slug/:signature',
    component: () => import('@/views/collection/detail.vue'),
    meta: {
      layout: readingLayout,
    },
  },
  {
    path: '/preview/activity-sheet',
    component: () => import('@/views/public/activity-sheet.vue'),
  },
  {
    path: '/preview/activity-sheet/:id',
    component: () => import('@/views/public/activity-sheet.vue'),
  },
]
