export default Object.freeze({
  loginByType: 'public/login-by-type',
  loginGoogle: 'public/login/family/google',
  childAdd: 'my/child',
  childUpdate: 'my/child/:childId',
  childLogin: 'my/child/:childId/login',
  register: 'public/register',
  registerSchool: 'public/register-school',
  registerGoogle: 'public/register/google',
  registerTeacher: 'public/register/teacher',
  registerTeacherMaster: 'public/register/teacher/master',
  registerTeacherIsEmailExist: 'public/register/teacher/is-email-exist',
  registerTeacherVerifyOtp: 'public/register/teacher/verify-otp',
  registerTeacherResendOtp: 'public/register/teacher/resend-otp',
  teacherForgotPassword: 'public/teacher/forgot-password',
  teacherResetPassword: 'public/teacher/reset-password',
  loginTeacher: 'public/login/teacher',
  verifyOtp: 'public/register/verify',
  resendOtp: 'public/register/resend-otp/:key',
  banners: 'public/banners',
  findSchool: 'public/find-school',
  homeBook: 'public/home-book',
  bookList: 'books',
  bookSetToLibrary: 'books/:bookId/set-to-library',
  book: 'public/book/:bookId',
  publicCollectionReading: '/public/collection/reading',
  publicCollection: '/public/collections',
  publicCollectionBySlug: '/public/collections-by-slug',
  publicHomeCollection: '/public/home-collections',
  publicHomeCollectionRecommendation:
    '/public/home-collections-recommendations',
  publicHomeBookRecommendation: 'public/home-books-recommendations',
  publicResendEmailVerification: 'public/resend-email-verification',
  publicBookByCategory: 'public/book/category/:categoryName',
  publicSubsTypeSchool: 'public/subs-type-school',
  publicSubsTypeFamily: 'public/subs-type-family',
  publicGeoCity: '/public/geography/city',
  publicGeoProvince: '/public/geography/province',
  publicSchoolMaster: '/public/school/master',
  publicSchoolMasterType: '/public/school/master/type',
  categories: '/public/categories',
  categoriesType: '/public/categories/type',
  myProfile: 'my/profile',
  myBook: 'my/read/:bookId',
  myReadLastLog: 'my/read/:bookId/latest-log',
  myReadActivity: 'my/read/:bookId/activity',
  myReadHistory: 'my/read-history-log',
  myReadSummary: 'my/read-summary',
  myReadSummaryWithDate: 'my/read-summary-with-date',
  mySummaryListOfActivities: 'my/summary-list-of-activities',
  mySummaryListOfActivitiesWithDate: 'my/summary-list-of-activities-with-date',
  mySetReadingList: 'my/set-users-to-reading-list',
  myFavorite: 'my/favorite',
  myFavoriteCollection: 'my/favorite/collection',
  myFavoriteCollectionAddRemove: 'my/favorite/collection/add-or-remove',
  myListActivities: 'my/list-of-activities',
  myListOfReads: 'my/list-of-reads',
  myListOfReadsId: 'my/list-of-reads/:id',
  myReadsProgress: 'my/list-of-reads/:id/progress',
  myChangePassword: 'my/update-password',
  myClass: 'my/class',
  myNotifications: 'my/notifications',
  myNotificationDetail: 'my/notifications/:notificationId',
  myNotificationsUnread: 'my/notifications/unread',
  mySetChildToSchool: 'my/set-child-to-school',
  myDetailChildToSchool: 'my/detail-child-to-school/:childId',
  myCancelChildToSchool: 'my/cancel-child-to-school',
  myReleaseChildToSchool: 'my/release-child-to-school',
  myOnboarding: 'my/onboarding',
  myCompleteOnboarding: 'my/complete-on-boarding',
  myTeacherChangePassword: 'my/teacher/change-password',
  myTeacherProfile: 'my/teacher/profile',
  myTeacherSchoolInfo: 'my/teacher/school-info',
  myTeacherUpdateFirstTimeLogin: 'my/teacher/update-firstimelogin',
  forgot: 'public/forgot-password',
  reset: 'public/reset-password',
  library: 'library',
  transactionSubTypes: 'transactions/subs-types',
  transactionCreateOrder: 'transactions/create-order',
  transactionVoucher: 'transactions/voucher-simulation',
  transactionCancelRecurring: 'transactions/cancel-recurring',
  transactionCardTokens: 'transactions/card-tokens/:token',
  transactionChangeCard: 'transactions/change-card',
  transactions: 'transactions/',
  publicPreviewBook: 'public/preview/book/:bookId/:signature',
  publicPreviewCollection: 'public/preview/collection/:slug/:signature',
  publicTotalFinish: 'public/book/total-finish',
  publicTotalSchool: 'public/total-school',
  publicTotalStudent: 'public/total-student',
  publicCampaign: 'public/campaigns/:slug',
  bookDefault: 'books/default',
  activitySheet: '/activity-sheets',
  activitySheetSection: '/activity-sheets/section',
  faq: '/faq',
  readingParade: '/reading/parade',
  readingParadeSlug: '/reading/parade/slug/:slug',
  readingParadeAchievementBadge: '/reading/parade/achievement/badge',
  readingParadeAchievementBadgeRead: '/reading/parade/achievement/:achievementId/read',
  readingParadeJoin: '/reading/parade/:paradeReadingId/join',
  readingParadeParticipants: '/reading/parade/:paradeReadingId/participants',
  readingParadeProgress: '/reading/parade/:paradeReadingId/progress',
  readingChallenge: '/reading/challenge',
  readingChallengeSlug: '/reading/challenge/slug/:slug',
  readingChallengeJoin: '/reading/challenge/:challengeReadingId/join',
  readingChallengeClaim: '/reading/challenge/:challengeReadingId/claim-reward',
  readingChallengeAchievementBadgeRead: '/reading/challenge/achievement/:achievementId/read',
  readingChallengeNotification: '/reading/challenge/notification',
  config: '/public/config',
  searchFamily: '/my/schools/family/search',
  getFamily: '/my/schools/family/search/:id',
  setStudentToFamily: '/my/schools/set-student-to-family',
  detailChildFromSchool: '/my/schools/detail-child-from-school/:studentId',
  cancelStudentToFamily: '/my/schools/cancel-student-to-family/:studentId',
  submitLoyaltyProgram: '/my/schools/loyalty-program',
  schoolChildDashboard: '/my/schools/child-dashboard',
  adminSchoolGenerateToken: '/admin/schools/:schoolId/generate-token',
})
