export default [
  {
    path: '/library',
    component: () => import('@/views/library/library.vue'),
  },
  {
    path: '/library/favorites',
    component: () => import('@/views/library/favorites.vue'),
  },
  {
    path: '/library/favorite-collection',
    component: () => import('@/views/library/favorite-collection.vue'),
  },
  {
    path: '/library/history',
    component: () => import('@/views/library/history-detail.vue'),
  },
  {
    path: '/library/read-history',
    component: () => import('@/views/library/read-history-detail.vue'),
  },
  {
    path: '/library/detail/:id',
    component: () => import('@/views/library/detail.vue'),
  },
  {
    path: '/library/:slug',
    component: () => import('@/views/library/detail.vue'),
  },
]
