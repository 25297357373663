<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs';
import Lazy from '@/components/v/lazy.vue'
import Btn2 from '@/components/v/btn-2.vue'

const NavbarPublic = defineAsyncComponent(() => import('@/components/navbar-public.vue'))
const NavbarLoginFloating = defineAsyncComponent(() => import('@/components/navbar-login-floating.vue'))
const AchievementBadgeNotice = defineAsyncComponent(() => import('@/components/game/achievement-badge-notice.vue'))
const UpdatePhoneNumberScreen = defineAsyncComponent(() => import('@/components/profile/update-phone-number-screen.vue'))
const WhatsappCta = defineAsyncComponent(() => import('@/components/contact/whatsapp-cta.vue'))

import app from '@/app'
import auth from '@/app/auth'

const route = useRoute()
const visibleWaCta = computed(() => {
  const paths = [
    '/sign-up/school',
    '/sign-in/school',
    '/sign-up/family',
    '/sign-in/family',
  ]
  const isPathMatch = paths.some(p => {
    return route.path.includes(p)
  })
  return !auth.isLogin && !isPathMatch
})

const visibleParadeBanner = computed(() => auth.isChild && auth.hasParadeOngoing)

const visibleTrialBanner = computed(() => {
  const isExplorePage = route.path.includes('/explore')
  const isAllowView = auth.isAdmin || auth.isTeacher
  return isAllowView && isExplorePage && auth.profile?.school?.packageName === 'Trial'
})
</script>

<template>
  <div class="layout-login font-jak">
    <div class="relative sticky z-10 top-0">
      <Lazy v-if="visibleParadeBanner"
        class="relative grad-orange h-44px md:h-60px text-white-base flex items-center justify-center text-xs md:text-lg">
        <span>
          Kamu sedang ikut Parade, lihat papan skor
          <RouterLink to="/game/parade" class="link">
            di sini
          </RouterLink>
        </span>
        <div class="absolute right-16px md:right-48px cursor-pointer" @click="auth.closeParadeBanner">
          <img src="@/assets/icon/close-button.svg" class="w-26px">
        </div>
      </Lazy>

      <Lazy v-if="visibleTrialBanner">
        <div class="bg-purple-100 text-white-base text-xs">
          <div class="lg:flex justify-center gap-2 items-center py-2 px-4">
            <div class="flex gap-2 mb-2 lg:mb-0 text-center justify-center">
              <div>Uji Coba Premium akan berakhir pada</div>
              <div class="font-bold">{{ dayjs(auth.profile?.school?.subsEndAt).format('DD MMM YYYY') }}</div>
            </div>
            <div class="border border-white-base rounded-lg p-2 flex gap-2 items-center justify-center">
              Optimalkan penggunaanmu
              <a href="https://api.whatsapp.com/send/?phone=6285171136399&text=Halo%2C+saya+mau+bertanya&type=phone_number&app_absent=0"
                target="_blank">
                <Btn2>
                  Konsultasi gratis
                </Btn2>
              </a>
            </div>
          </div>
        </div>
      </Lazy>

      <div v-if="app.auth.isLogin" class="relative">
        <NavbarLoginFloating>
        </NavbarLoginFloating>
      </div>
    </div>

    <!-- <NavbarLoginFloating v-if="app.auth.isLogin" :class="visibleParadeBanner ? '!top-44px !md:top-60px' : ''">
    </NavbarLoginFloating> -->
    <NavbarPublic v-if="!app.auth.isLogin"></NavbarPublic>

    <AchievementBadgeNotice v-if="auth.isLogin" />
    <UpdatePhoneNumberScreen v-if="auth.isFamily && auth.shouldInputPhoneNumber" />

    <div :class="['layout-login__content', { 'layout-login__content--public': !app.auth.isLogin }]">
      <slot></slot>
    </div>

    <div v-if="visibleWaCta">
      <WhatsappCta></WhatsappCta>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout-login {
  &__content {
    :deep(>div) {
      padding-top: 72px;
      @screen md {
        padding-top: 82px;
      }
    }
    &--public {
      :deep(>div) {
        padding-top: 0;
        @screen md {
          padding-top: 0;
        }
      }
    }
  }
}
</style>