import 'virtual:windi.css'
import { createApp } from 'vue'
import router from '@/router'
import App from '@/App.vue'
import scriptx from 'vue-scriptx'
import vue3GoogleLogin from 'vue3-google-login'
import * as amplitude from '@amplitude/analytics-browser';

createApp(App)
  .use(router)
  .use(scriptx)
  .use(vue3GoogleLogin, {
    clientId: '404495525670-634dhd70s0mhqlftsvd0a1jba0fgd9nq.apps.googleusercontent.com'
  })
  .mount('#app')

amplitude.init('f8396476bf2fb27e932e6133858c269c');
