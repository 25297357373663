const config = Object.freeze({
  title: 'PIBO',
  longTitle: 'PIBO: Akses 600+ Buku Anak dan lainnya!',
  desc: 'Jelajahi beragam cerita dan temukan keseruan belajar bersama PIBO; aman bagi anak usia 4-12 tahun dengan buku dan permainan yang dikurasi secara profesional.',
  supportEmail: 'support@bacapibo.com',
  collabEmail: 'chanif@bacapibo.com',
  loyaltyUrl: 'https://form.jotform.com/231003427234038'
})

export default config
