import { reactive, computed } from '@vue/reactivity'
import Cookies from 'js-cookie'
import api from '@/api'
import router from '@/router'
import dayjs from 'dayjs'

const COOKIE_PIBO_TOKEN = 'pibo-token'
const COOKIE_PIBO_CHILD = 'pibo-child-id'
const COOKIE_PIBO_IS_STUDENT = 'pibo-is-student'
const PARADE_BANNER_INTERVAL = 24 * 60 * 60 * 1000 // 1d

const auth = reactive({
  isDev: import.meta.env.VITE_IS_DEV,
  isEnableMembership: import.meta.env.VITE_ENABLE_MEMBERSHIP,
  token: '',
  profile: {
    userId: 0,
    name: '',
    email: '',
    expiredAt: '',
    birthDate: '',
    gender: '',
    provinceId: 0,
    cityId: 0,
    connectedStatus: 0,
    address: '',
    PostalCode: '',
    PhoneNumber: '',
    icon: 1,
    isEmailVerified: false,
    isShowBannerLoyalty: false,
    isShowParadeBanner: false,
    isFirstTimeLogin: false,
    isAdmin: false,
    status: '',
    subsType: null,
    lastLoginAt: '',
    lastSeenAt: '',
    membershipStatus: '',
    onBoardingStatus: 0,
    createAt: '',
    updateAt: '',
    registerKey: '',
    type: 0,
    childs: [],
    school: null
  },
  activeChildId: null,
  unreadCount: 0,
  shouldInputPhoneNumber: false,
  shouldInputSchoolData: false,
  authModal: null,
  authRedirect: null,
  cart: null,
  hasParadeOngoing: false,

  userId: computed(() => auth.activeChildId || auth.profile?.userId || null),

  isLogin: computed(() => !!auth.profile.userId),

  isFamily: computed(() => auth.profile.type === 4),

  isSchool: computed(() => auth.isTeacher || auth.isStudent),

  isParent: computed(() => (auth.isFamily || auth.isTeacher) && !auth.isChild),

  isAdmin: computed(() => auth.profile.isAdmin),

  isTeacher: computed(() => auth.profile.type === 7),

  isStudent: computed(() => auth.profile.type === 8),

  isChild: computed(() => !!auth.activeChildId || auth.isStudent),

  childs: computed(() => auth.profile.childs || []),

  activeChild: computed(() => {
    let child = {}
    if (auth.activeChildId) {
      child = auth.profile.childs?.find((el) => {
        return String(el.subUserId) === String(auth.activeChildId)
      })
    }
    return child || {}
  }),

  isMember: computed(() => {
    return auth.profile.membershipStatus === ''
  }),

  isPremium: computed(() => {
    return auth.profile.membershipStatus === 'Premium'
  }),

  isRecurring: computed(() => {
    return auth.profile.subsType?.isRecurring
  }),

  async login(body) {
    const result = await api.post(api.path.loginByType, body)
    const token = result.data?.token
    if (token) {
      auth.token = token ? `Bearer ${token}` : ''
      auth.type = result.data.type
      auth.activeChildId = null
      Cookies.set(COOKIE_PIBO_TOKEN, auth.token)
      Cookies.remove(COOKIE_PIBO_CHILD)
      Cookies.remove(COOKIE_PIBO_IS_STUDENT)
      if (auth.type === 8) {
        Cookies.set(COOKIE_PIBO_IS_STUDENT, 1)
        Cookies.set(COOKIE_PIBO_CHILD, result.data.userId)
        auth.activeChildId = result.data.userId
      }
      api.setHeader('Authorization', auth.token)
      await auth.fetchProfile(auth.type === 8)
    }
    auth.setShouldInputPhoneNumber()
    return result
  },

  async loginGoogle(body) {
    const result = await api.post(api.path.loginGoogle, body)
    const token = result.data?.token
    if (token) {
      auth.token = token ? `Bearer ${token}` : ''
      auth.type = result.data.type
      auth.activeChildId = null
      Cookies.set(COOKIE_PIBO_TOKEN, auth.token)
      Cookies.remove(COOKIE_PIBO_CHILD)
      Cookies.remove(COOKIE_PIBO_IS_STUDENT)
      if (auth.type === 8) {
        Cookies.set(COOKIE_PIBO_IS_STUDENT, 1)
        Cookies.set(COOKIE_PIBO_CHILD, result.data.userId)
        auth.activeChildId = result.data.userId
      }
      api.setHeader('Authorization', auth.token)
      await auth.fetchProfile(auth.type === 8)
    }
    auth.setShouldInputPhoneNumber()
    return result
  },

  async loginTeacher(body) {
    const result = await api.post(api.path.loginTeacher, body)
    const token = result.data?.token

    auth.token = token ? `Bearer ${token}` : ''
    auth.type = result.data?.type
    auth.activeChildId = null
    Cookies.set(COOKIE_PIBO_TOKEN, auth.token)
    Cookies.remove(COOKIE_PIBO_CHILD)
    Cookies.remove(COOKIE_PIBO_IS_STUDENT)
    api.setHeader('Authorization', auth.token)

    if (!result.data?.isAdmin || result.data?.isEmailVerified) {
      await auth.fetchProfile()
    }

    if (result.data?.isEmailVerified) {
      auth.setShouldInputSchoolData()
    }

    return result
  },

  async registerGoogle(body) {
    const result = await api.post(api.path.registerGoogle, body)
    const token = result.data?.token
    if (token) {
      auth.token = token ? `Bearer ${token}` : ''
      auth.type = result.data.type
      auth.activeChildId = null
      Cookies.set(COOKIE_PIBO_TOKEN, auth.token)
      Cookies.remove(COOKIE_PIBO_CHILD)
      Cookies.remove(COOKIE_PIBO_IS_STUDENT)
      const isStudent = auth.type === 8
      if (isStudent) {
        Cookies.set(COOKIE_PIBO_IS_STUDENT, 1)
        Cookies.set(COOKIE_PIBO_CHILD, result.data.userId)
        auth.activeChildId = result.data.userId
      }
      api.setHeader('Authorization', auth.token)
      await auth.fetchProfile(isStudent)
    }
    return result
  },

  setToken(token) {
    Cookies.set(COOKIE_PIBO_TOKEN, 'Bearer ' + token)
  },

  async fetchProfile(isStudent) {
    return api
      .get(api.path.myProfile, {
        params: {
          childID: isStudent ? auth.activeChildId : undefined,
        },
      })
      .then((res) => {
        Object.assign(auth.profile, res.data)
        auth.fetchUnreadCount()
        auth.fetchHasParadeOngoing()

        if (auth.isFamily && !auth.profile?.isEmailVerified) {
          router.replace('/verify')
        }

        if ((auth.isAdmin || auth.isTeacher) && !auth.profile?.isEmailVerified) {
          router.replace('/sign-up/school?key=' + res.data.registerKey)
        }

        return res.data
      })
      .catch((e) => {
        auth.logout()
      })
  },

  async register(body) {
    const result = await api.post(api.path.register, body)
    return result
  },

  async addChild(body) {
    const result = await api.post(api.path.childAdd, body)
    await auth.fetchProfile()
    return result
  },

  async switchProfileChildAuth(childId, pin) {
    return api
      .post(api.path.childLogin.replace(':childId', childId), {
        token: pin,
      })
      .then((res) => {
        const isStudent = res?.data?.schoolId && !res?.data?.isTeacher
        auth.switchProfileChild(childId, isStudent)
        auth.loadAuth()
      })
  },

  switchProfileChild(childId, isStudent) {
    if (!childId) return
    auth.activeChildId = Number(childId)
    Cookies.set(COOKIE_PIBO_CHILD, childId)
    if (isStudent) {
      Cookies.set(COOKIE_PIBO_IS_STUDENT, 1)
    }
  },

  async loadAuth() {
    const token = Cookies.get(COOKIE_PIBO_TOKEN)
    const childId = Cookies.get(COOKIE_PIBO_CHILD)
    const isStudent = Boolean(Number(Cookies.get(COOKIE_PIBO_IS_STUDENT)))
    auth.switchProfileChild(childId, isStudent)

    if (token) {
      auth.token = token
      api.setHeader('Authorization', auth.token)
      await auth.fetchProfile(isStudent)
    }
    return auth.isLogin
  },

  async logout() {
    api.setHeader('Authorization', undefined)
    Cookies.remove(COOKIE_PIBO_TOKEN)
    Cookies.remove(COOKIE_PIBO_CHILD)
    Cookies.remove(COOKIE_PIBO_IS_STUDENT)
  },

  async findSchool(q) {
    return api.get(api.path.findSchool, { params: { q } })
  },

  async fetchUnreadCount() {
    return api
      .get(api.path.myNotificationsUnread, {
        params: {
          childID: auth.activeChildId || undefined,
        },
      })
      .then((res) => {
        auth.unreadCount = res?.data?.total || 0
      })
      .catch(console.error)
  },

  setShouldInputPhoneNumber() {
    if (auth.isParent || auth.isTeacher) {
      if (!auth.profile.PhoneNumber) {
        auth.shouldInputPhoneNumber = true
      }
    }
  },

  setShouldInputSchoolData() {
    if (auth.profile?.school) {
      if (!auth.profile?.school?.adminName ||
        !auth.profile?.school?.address ||
        !auth.profile?.school?.cityId ||
        !auth.profile?.school?.educationalLevel
      ) {
        auth.shouldInputSchoolData = true
      }
    }
  },

  fetchHasParadeOngoing() {
    const paradeBannerClosedAt = Number(localStorage.paradeBannerClosedAt)

    let shouldShowBanner = true
    if (paradeBannerClosedAt) {
      shouldShowBanner = dayjs().isAfter(paradeBannerClosedAt + PARADE_BANNER_INTERVAL)
    }

    if (!shouldShowBanner) return
    localStorage.removeItem('paradeBannerClosedAt')

    auth.hasParadeOngoing = auth.profile.isShowParadeBanner
  },

  closeParadeBanner() {
    auth.hasParadeOngoing = false
    localStorage.paradeBannerClosedAt = Date.now()
  }
})

export default auth
